import React from 'react';
import {makeStyles} from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import {colors} from '../theme/colors';

export interface DialogButtonProps {
  onClick: () => void;
  positionLeft?: boolean;
  icon?: React.ReactElement;
}

const useStyles = makeStyles({
  button: {
    position: 'absolute',
    top: 16,
    border: `1px solid ${colors.alto}`,
    borderRadius: 4,
    padding: 3,
    zIndex: 1,
    backgroundColor: colors.white,
  },
  rightButton: {
    right: 52,
  },
  leftButton: {
    left: 16,
  },
});

export const DialogButton = (props: DialogButtonProps) => {
  const classes = useStyles();

  return (
    <IconButton
      aria-label="button"
      className={`${classes.button} ${
        props.positionLeft ? classes.leftButton : classes.rightButton
      }`}
      onClick={props.onClick}
      size="large">
      {props.icon}
    </IconButton>
  );
};
