import React from 'react';
import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';
import {LandlordSideBarContent} from '../../routes/Landlord/LandlordSideBarContent';
import {useClientType} from '@/hooks/useClientType/useClientType';
import {ManagerSideBarContent} from '../../routes/Manager/ManagerSideBarContent';
import {ConciergeSideBarContent} from '../../routes/Concierge/ConciergeSideBarContent';
import {useGetUserTypeQuery} from '@/generated/graphql';
import FooterContent from './FooterContent';

const useStyles = makeStyles({
  sidebar: {
    position: 'fixed',
    marginLeft: 40,
    paddingTop: 120,
    paddingBottom: 10,
    top: 0,
    bottom: 0,
    '@media print': {display: 'none'},
    overflowY: 'scroll',
    scrollbarColor: 'transparent',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
});

const SideBar: React.FC = () => {
  const styles = useStyles();
  const clientType = useClientType();

  const {data} = useGetUserTypeQuery();

  const isLandlord = clientType === 'BTL';
  const isEnterprise = clientType === 'ENT';

  return (
    <Box className={styles.sidebar}>
      {isLandlord && <LandlordSideBarContent />}
      {isEnterprise && data?.getUser?.manager && <ManagerSideBarContent />}
      {isEnterprise && !data?.getUser?.manager && data?.getUser?.concierge && (
        <ConciergeSideBarContent />
      )}
      {isLandlord && <FooterContent />}
    </Box>
  );
};

export default SideBar;
