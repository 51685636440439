import React from 'react';
import {
  Box,
  ImageList,
  ImageListItem,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import Photographer from '../assets/photographer.svg';
import {AddAPhoto, Delete} from '@mui/icons-material';
import {SxProps} from '@mui/system';
import {makeStyles} from '@mui/styles';
import {colors} from '../theme/colors';
import {
  calculateGridSize,
  calculateGridSizeEdit,
  getEditButtonSize,
} from '../formatters/images';
import {ImageType} from './Carousel';

const useStyles = makeStyles(theme => ({
  addImage: {
    background: colors.wildSand,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8,
    },
  },
  addNewImage: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: colors.wildSand,
    width: '800px',
    height: '400px',
    [theme.breakpoints.down('md')]: {
      width: '200%',
      height: '200px',
    },
  },
  icon: {
    width: '42px',
    height: '42px',
    color: colors.fadedText,
  },
  container: {
    display: 'flex',
    marginBottom: '32px',
    justifyContent: 'space-between',
  },
  gridImage: {
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8,
    },
    pointerEvents: 'auto',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: 200,
    background: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
    color: colors.white,
    fontSize: '36px',
  },
  deleteOverlay: {
    position: 'absolute',
    top: '3%',
    right: '3%',
    width: '32px',
    height: '32px',
    background: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
    color: colors.white,
    borderRadius: '50%',
    opacity: 0.8,
    cursor: 'pointer',
    '&:hover': {
      opacity: 1,
    },
  },
  svg: {
    width: '100%',
    height: '100%',
    margin: '44px 64px',
    [theme.breakpoints.down('md')]: {
      width: '200px',
      height: '200px',
    },
  },
}));

type Size = {
  rows: number;
  columns: number;
};

export interface ImageGridProps {
  sx?: SxProps;
  totalCols?: number;
  rowHeight?: number;
  edit?: boolean;
  setIndex: (index: number) => void;
  onChange?: (files: Array<string | File | ImageType>) => void;
  value?: Array<string | File | ImageType>;
  onDelete: (index: number) => void;
  imagePrefix?: string;
}

export const ImageGrid: React.FC<ImageGridProps> = props => {
  const styles = useStyles();
  const [size, setSize] = React.useState<Size>();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const fileRef = React.useRef<HTMLInputElement>(null);
  const calculateLength = () => {
    if (isMobile && props.edit) {
      return 2;
    }
    if (props.edit) {
      return 3;
    }
    if (isMobile) {
      return 3;
    }

    return 5;
  };

  const transformedImages = React.useMemo(() => {
    return props.value
      ? props.value.map((image, index, array) => {
          return {
            src: image,
            size: props.edit
              ? calculateGridSizeEdit(index, array.length, isMobile)
              : calculateGridSize(index, array.length, isMobile),
          };
        })
      : [];
  }, [props.value, isMobile, props.edit]);

  const handleAddPhoto = () => {
    fileRef?.current?.click();
  };

  React.useEffect(() => {
    setSize(getEditButtonSize(transformedImages.length, isMobile));
  }, [transformedImages.length, isMobile]);

  const difference = transformedImages.length - 5;
  const editDifference = transformedImages.length - 3;
  const showDeleteIcon = (
    position: number,
    image: File | string | ImageType,
  ) => {
    if (image instanceof File || typeof image === 'string') {
      return false;
    }
    if (props.edit) {
      if (!isMobile && editDifference > 0 && position === 2) {
        return false;
      }
      if (isMobile && editDifference > 0 && position === 1) {
        return false;
      }
      return true;
    }
    return false;
  };

  return (
    <>
      {transformedImages.length > 0 ? (
        <ImageList
          sx={props.sx}
          variant="quilted"
          cols={props.totalCols}
          rowHeight={props.rowHeight}>
          {transformedImages.length > 0 &&
            transformedImages.slice(0, calculateLength()).map((item, idx) => {
              return (
                <ImageListItem
                  key={idx}
                  cols={item.size.columns}
                  rows={item.size.rows}
                  onClick={() => {
                    if (!props.edit) {
                      props.setIndex(idx);
                    }
                  }}>
                  <img
                    className={styles.gridImage}
                    src={
                      item.src && item.src instanceof File
                        ? URL.createObjectURL(item.src)
                        : typeof item.src === 'string'
                        ? `${props.imagePrefix}${item.src}`
                        : `${props.imagePrefix}${item.src.image}`
                    }
                    alt=""
                  />
                  {showDeleteIcon(idx, item.src) && (
                    <div className={styles.deleteOverlay}>
                      <Delete
                        onClick={(e: React.MouseEvent) => {
                          props.onDelete(idx);
                          e.preventDefault();
                        }}
                      />
                    </div>
                  )}
                  {idx === 4 && difference > 0 && (
                    <div className={styles.overlay}>+{difference}</div>
                  )}
                  {idx === 2 &&
                    editDifference > 0 &&
                    (props.edit || isMobile) && (
                      <div className={styles.overlay}>+{editDifference}</div>
                    )}
                  {idx === 1 &&
                    editDifference + 1 > 0 &&
                    props.edit &&
                    isMobile && (
                      <div className={styles.overlay}>
                        +{editDifference + 1}
                      </div>
                    )}
                </ImageListItem>
              );
            })}

          {props.edit && (
            <ImageListItem cols={size?.columns} rows={size?.rows}>
              <Box className={styles.addImage} onClick={handleAddPhoto}>
                <AddAPhoto
                  sx={{width: '42px', height: '42px', color: colors.fadedText}}
                />
                <Typography sx={{color: colors.fadedText}} variant="h4">
                  Add Photos
                </Typography>
              </Box>
            </ImageListItem>
          )}
        </ImageList>
      ) : (
        <Box width="50%">
          {props.edit ? (
            <Box className={styles.addNewImage} onClick={handleAddPhoto}>
              <AddAPhoto
                sx={{width: '42px', height: '42px', color: colors.fadedText}}
              />
              <Typography sx={{color: colors.fadedText}} variant="h4">
                Add Photos
              </Typography>
            </Box>
          ) : (
            <img src={Photographer} alt="" className={styles.svg} />
          )}
        </Box>
      )}
      <input
        id="images"
        name="images"
        type="file"
        multiple
        accept="image/*"
        ref={fileRef}
        style={{display: 'none'}}
        onChange={event => {
          if (event.currentTarget.files) {
            props.onChange &&
              props.onChange([
                ...(props.value || []),
                ...Array.from(event.currentTarget.files),
              ]);
          }
        }}
      />
    </>
  );
};

export default ImageGrid;
