import {Box, Button} from '@mui/material';
import {makeStyles} from '@mui/styles';
import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {
  ConversationRole,
  ListConversationsQuery,
  useListConversationsQuery,
} from '../../generated/graphql';
import {useSelectedCommunities} from '../CommunityFilter';
import {SearchBar} from '../SearchBar';
import {ConversationListItemProps} from './ConversationListItem';
import {
  ConversationsFilters,
  FilterName,
  FilterValues,
  openFilter,
  tenantOpenFilter,
} from './ConversationsFilters';
import {ConversationsList} from './ConversationsList';

const useStyles = makeStyles(theme => ({
  header: {
    marginBottom: 32,
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      marginBottom: '12px',
      gap: '12px',
    },
  },
  search: {
    width: 300,
    marginBottom: 0,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  button: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

export type ConversationEvent = {
  id: string;
  createdAt: string;
  messageSender?: string;
  mediaSender?: string | null;
  body?: string | null;
  type?: string | null;
  __typename?: string;
};

export type ConversationListItemData = {
  conversationId: string;
  latestEvent?: ConversationEvent | null;
  userName?: string | null;
  isUnread: boolean;
  homeName: string;
  urgency?: string | null;
  subject: string | null;
  closedAt?: string | null;
  snoozedUntil?: string | null;
};

export type EnterpriseNewConversationProps = {
  communityId?: string;
  homeId?: string;
  residencyId?: string;
  residentId?: string;
  onCreate?: (id: string) => void;
  contactId?: string;
};

export type NewConversationProps = {
  open: boolean;
  onClose: () => void;
} & EnterpriseNewConversationProps;

export type Tabs = 'thread' | 'details';

export type Sender = 'Resident' | 'Manager';

export type ViewConversationProps = {
  open: boolean;
  conversationId: string;
  onClose: () => void;
  returnPath?: string;
};

const mapConversations = (
  gqlData: ListConversationsQuery | undefined,
): ConversationListItemData[] => {
  const conversationListData: ConversationListItemData[] = [];
  gqlData?.conversations?.edges?.forEach(edge => {
    const node = edge?.node;

    if (node) {
      const listItemData: ConversationListItemData = {
        conversationId: node.id,
        latestEvent: node.latestEvent,
        isUnread: node.hasUnread,
        //@ts-ignore
        homeName: node.home.name,
        urgency: node.currentUrgency,
        subject: node.subject,
        closedAt: node.closedAt,
        snoozedUntil: node.snoozedUntil,
        userName:
          //@ts-ignore
          node.user?.firstName && node.user?.firstName.trim() !== ''
            ? //@ts-ignore
              `${node.user?.firstName} ${node.user?.lastName}`
            : //@ts-ignore
              node.user?.email,
      };
      conversationListData.push(listItemData);
    }
  });
  return conversationListData;
};

type Props = {
  enabledFilters: FilterName[];
  ConversationListItemComponent: React.FC<ConversationListItemProps>;
  NewConversationComponent: React.FC<NewConversationProps>;
  ViewConversationComponent: React.FC<ViewConversationProps>;
  sender: Sender;
  contactId?: string;
};

export const Conversations = ({
  ConversationListItemComponent,
  NewConversationComponent,
  ViewConversationComponent,
  enabledFilters,
  sender,
  contactId,
}: Props) => {
  const styles = useStyles();
  const communities = useSelectedCommunities();
  const [viewConversationOpen, setViewConversationOpen] = useState(false);
  const [newConversation, setNewConversation] = useState(false);
  const {conversationId} = useParams<{conversationId: string | undefined}>();
  const [search, setSearch] = useState('');
  const [filterValues, setFilterValues] = useState<FilterValues>({
    //@ts-ignore
    communities: [],
    date: [],
    home: [],
    status: [openFilter],
    orderBy: [{label: 'Newest', value: '-last_event_time'}],
    tenantStatus: [tenantOpenFilter],
  });

  useEffect(() => {
    setViewConversationOpen(Boolean(conversationId));
  }, [conversationId]);

  const statusFilterValues = (
    enabledFilters.includes('tenantStatus')
      ? filterValues.tenantStatus
      : filterValues.status
  )
    .map(s => s.value.split(','))
    .flat();

  const {data, loading, fetchMore} = useListConversationsQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    variables: {
      search,
      orderBy: filterValues.orderBy[0]?.value,
      communities,
      status: statusFilterValues,
      date: filterValues.date[0]?.value,
      home: filterValues.home[0]?.value,
      sender: ConversationRole[sender],
      contact: contactId,
    },
  });

  const onBottom = () => {
    if (data?.conversations?.pageInfo?.hasNextPage) {
      fetchMore({
        variables: {
          after: data.conversations.pageInfo.endCursor,
        },
      });
    }
  };

  const isFiltered = ![
    filterValues.date.length === 0,
    filterValues.status.length === 0,
    communities.length === 0,
    search === '',
  ].every(Boolean);

  return (
    <>
      <Box className={styles.header}>
        <SearchBar onChange={setSearch} label="Search for a conversation..." />
        <Box>
          <Button
            onClick={() => {
              setNewConversation(true);
            }}
            color="secondary"
            className={styles.button}
            variant="contained"
          >
            New conversation
          </Button>
        </Box>
      </Box>
      <ConversationsFilters
        enabledFilters={enabledFilters}
        filterValues={filterValues}
        setFilterValues={setFilterValues}
      />
      <ConversationsList
        isFiltered={isFiltered}
        data={mapConversations(data)}
        sender={sender}
        ListItemComponent={ConversationListItemComponent}
        onScrolledToBottom={onBottom}
        loading={loading}
      />
      <NewConversationComponent
        communityId={undefined}
        open={newConversation}
        onClose={() => setNewConversation(false)}
      />
      {conversationId && (
        <ViewConversationComponent
          open={viewConversationOpen}
          conversationId={conversationId}
          onClose={() => setViewConversationOpen(false)}
        />
      )}
    </>
  );
};
