import {Box} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {DateTime, Duration} from 'luxon';
import {useListHomesForConversationsQuery} from '../../generated/graphql';
import {CommunityFilter} from '../CommunityFilter';
import {SearchSelect, SearchSelectValue} from '../SearchSelect';

const useStyles = makeStyles(theme => ({
  filtersContainer: {
    display: 'flex',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
}));

export type FilterName =
  | 'communities'
  | 'home'
  | 'date'
  | 'status'
  | 'tenantStatus';

export type FilterValues = {
  home: SearchSelectValue;
  date: SearchSelectValue;
  status: SearchSelectValue;
  tenantStatus: SearchSelectValue;
  orderBy: SearchSelectValue;
};

const sortOptions: {value: string; label: string}[] = [
  {value: 'last_event_time', label: 'Oldest'},
  {value: '-last_event_time', label: 'Newest'},
  {value: 'latest_updated_date', label: 'Waiting Longest'},
  {value: 'urgencies__urgency', label: 'Priority'},
];

const dateFilterOptions: {value: string; label: string}[] = [
  {value: DateTime.now().toISODate() as string, label: 'Today'},
  {
    value: DateTime.now()
      .minus(Duration.fromObject({days: 1}).toMillis())
      .toISODate() as string,
    label: 'Yesterday',
  },
  {
    value: DateTime.now()
      .minus(Duration.fromObject({days: 7}).toMillis())
      .toISODate() as string,
    label: 'Last 7 days',
  },
  {
    value: DateTime.now()
      .minus(Duration.fromObject({days: 30}).toMillis())
      .toISODate() as string,
    label: 'Last 30 days',
  },
  {
    value: DateTime.now()
      .minus(Duration.fromObject({days: 90}).toMillis())
      .toISODate() as string,
    label: 'Last 90 days',
  },
];

export const openFilter = {value: 'OP', label: 'Open'};
export const tenantOpenFilter = {value: 'OP,SN', label: 'Open'};
const snoozeFilter = {value: 'SN', label: 'Snoozed'};
const closedFilter = {value: 'CL', label: 'Closed'};

const statusFilterOptions = [openFilter, snoozeFilter, closedFilter];
const tenantStatusFilterOptions = [tenantOpenFilter, closedFilter];

type Props = {
  enabledFilters: FilterName[];
  filterValues: FilterValues;
  setFilterValues: (filters: FilterValues) => void;
};

export const ConversationsFilters = ({
  enabledFilters,
  filterValues,
  setFilterValues,
}: Props) => {
  const styles = useStyles();
  const setSortBy = (value: SearchSelectValue) => {
    setFilterValues({...filterValues, orderBy: value});
  };
  const setDateFilter = (value: SearchSelectValue) => {
    setFilterValues({...filterValues, date: value});
  };
  const setStatusFilter = (value: SearchSelectValue) => {
    setFilterValues({...filterValues, status: value});
  };
  const setTenantStatusFilter = (value: SearchSelectValue) => {
    setFilterValues({...filterValues, tenantStatus: value});
  };
  const setHomeFilter = (value: SearchSelectValue) => {
    setFilterValues({...filterValues, home: value});
  };

  const {data} = useListHomesForConversationsQuery({
    skip: !enabledFilters.includes('home'),
  });

  const homesOptions =
    data?.listHomes?.map((home: {id: string; name: string}) => ({
      value: home.id,
      label: home.name,
    })) || [];

  return (
    <Box className={styles.filtersContainer}>
      {enabledFilters.includes('communities') && <CommunityFilter fullWidth />}
      {enabledFilters.includes('home') && (
        <SearchSelect
          filterSelectedOptions
          options={homesOptions}
          value={filterValues.home}
          setValue={setHomeFilter}
          label="Home"
          fullWidth
        />
      )}
      {enabledFilters.includes('date') && (
        <SearchSelect
          filterSelectedOptions
          options={dateFilterOptions}
          value={filterValues.date}
          setValue={setDateFilter}
          label="Date"
          fullWidth
        />
      )}
      {enabledFilters.includes('status') && (
        <SearchSelect
          filterSelectedOptions
          options={statusFilterOptions}
          value={filterValues.status}
          setValue={setStatusFilter}
          label="Status"
          multiple
          fullWidth
        />
      )}
      {enabledFilters.includes('tenantStatus') && (
        <SearchSelect
          filterSelectedOptions
          options={tenantStatusFilterOptions}
          value={filterValues.tenantStatus}
          setValue={setTenantStatusFilter}
          label="Status"
          multiple
          fullWidth
        />
      )}
      <Box>
        <SearchSelect
          options={sortOptions}
          value={filterValues.orderBy}
          setValue={setSortBy}
          label="Sort by"
          fullWidth
        />
      </Box>
    </Box>
  );
};
