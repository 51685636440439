import React, {useContext} from 'react';
import {communityContext} from '../contexts/Community';
import {SearchSelectValue} from './SearchSelect';
import {
  CommunitySelect,
  CommunitySelectProps,
} from './CommunitySelect/CommunitySelect';

import {track} from '@amplitude/analytics-browser';

interface CommunityFilterContextType {
  value: SearchSelectValue;
  setValue: (value: SearchSelectValue) => void;
  disabled: boolean;
}

export const CommunityFilterContext =
  React.createContext<CommunityFilterContextType>({
    value: [],
    setValue: () => {},
    disabled: false,
  });

type CommunityFilterProviderProps = {
  children: React.ReactNode;
};

export const useCommunityFilter = () => {
  const context = useContext(CommunityFilterContext);
  if (!context) {
    throw new Error(
      'useCommunityFilter must be used within a CommunityFilterProvider',
    );
  }
  return context;
};

export const CommunityFilterProvider = ({
  children,
}: CommunityFilterProviderProps) => {
  const [value, setValue] = React.useState<SearchSelectValue>([]);
  const {communities} = React.useContext(communityContext);

  const disabled = Boolean(communities && communities.length === 1);

  React.useEffect(() => {
    if (communities && communities.length > 0) {
      setValue(
        communities.map(community => ({
          value: community.id,
          label: community.name!,
        })),
      );
    }
  }, [communities]);

  return (
    <CommunityFilterContext.Provider value={{value, setValue, disabled}}>
      {children}
    </CommunityFilterContext.Provider>
  );
};

export function useSelectedCommunities(): string[] {
  const {value} = React.useContext(CommunityFilterContext);

  const communities = value.map(x => x.value);
  return communities;
}

type CommunityFilterProps = {
  fullWidth?: boolean;
  onChange?: CommunitySelectProps['onChange'];
};

export const CommunityFilter = (props: CommunityFilterProps) => {
  const {value, setValue, disabled} = React.useContext(CommunityFilterContext);
  return (
    <CommunitySelect
      fullWidth={props.fullWidth}
      disabled={disabled}
      value={value}
      onChange={v => {
        props.onChange?.(v);
        track('Community Filter', {
          Selected: value.map(v => v.label),
        });
        setValue(v);
      }}
    />
  );
};
