import {
  ClientAdminItemFragment,
  CommunitySettingsFragment,
  ConciergeItemFragment,
  ManagerItemFragment,
  useGetUserTypeQuery,
} from '@/generated/graphql';
import {createContext, useContext, useEffect} from 'react';
import {useClarity} from 'use-clarity';

type CommunityManager = {
  id: string;
};

type UserTypeContextType = {
  email: string;
  manager: ManagerItemFragment | null;
  clientAdmin: ClientAdminItemFragment | null;
  concierge: ConciergeItemFragment | null;
  communityManager: CommunityManager | null;
  loading: boolean;
};

const defaultUserTypeContext: UserTypeContextType = {
  email: '',
  manager: null,
  clientAdmin: null,
  concierge: null,
  communityManager: null,
  loading: true,
};

export const useUserTypeContext = () => {
  const context = useContext(UserTypeContext);
  if (!context) {
    throw new Error(
      'useUserTypeContext must be used within a UserTypeProvider',
    );
  }
  return context;
};

const UserTypeContext = createContext<UserTypeContextType>(
  defaultUserTypeContext,
);

export const UserTypeProvider = ({children}: {children: React.ReactNode}) => {
  const {data, loading} = useGetUserTypeQuery();
  const {set, identify} = useClarity();

  const email = data?.getUser?.email ?? '';
  const clientAdmin = data?.getUser?.clientAdmin ?? null;
  const manager = data?.getUser?.manager ?? null;
  const concierge = data?.getUser?.concierge ?? null;
  const communityManager = data?.getUser?.communityManager ?? null;

  useEffect(() => {
    const conciergeClient = data?.getUser?.concierge?.client;
    const managerClient = data?.getUser?.manager?.client;
    const clientAdminClient = data?.getUser?.clientAdmin?.client;

    try {
      if (clientAdminClient) {
        set('client', clientAdminClient.name);
      } else if (managerClient) {
        set('client', managerClient.name);
      } else if (conciergeClient) {
        set('client', conciergeClient.name);
      }
      if (data?.getUser?.email) {
        identify(data?.getUser?.email);
      }
    } catch (e) {
      //do nothing
    }
  }, [data?.getUser]);

  return (
    <UserTypeContext.Provider
      value={{
        email,
        manager,
        clientAdmin,
        concierge,
        communityManager,
        loading,
      }}
    >
      {children}
    </UserTypeContext.Provider>
  );
};
