import {ConversationEvent} from '../components/Conversations/Conversations';
import {colors} from '../theme/colors';

export const handleStatus = (
  closedAt?: string | null,
  snoozedUntil?: string | null,
) => {
  const isSnoozed = snoozedUntil
    ? new Date(snoozedUntil).getTime() > new Date().getTime()
    : false;

  if (isSnoozed) {
    return {label: 'Snoozed', color: colors.fadedPurple};
  }
  if (!snoozedUntil && !!closedAt) {
    return {label: 'Closed', color: colors.fadedText};
  }
  return {label: 'Open', color: colors.treePoppy};
};

export const messageTextFromEvent = (
  latestEvent?: ConversationEvent | null,
  residentName?: string | null,
) => {
  const typeName = latestEvent?.__typename;
  switch (typeName) {
    case 'ConversationMediaType':
      return `${residentName} sent an image`;
    case 'ConversationMessageType':
      return latestEvent?.body;
    case 'ConversationActionModelType':
      switch (latestEvent?.type) {
        case 'RM':
          return 'Resident passed to RMG';
        case 'RG':
          return 'Resident has spoken with RMG';
        case 'FS':
          return 'Resident passed to FixFlo';
        case 'FE':
          return 'Resident created issue';
        case 'ASANA_TASK_CREATED':
          return 'Maintenance task created in Asana';
        case 'ASANA_TASK_COMPLETED':
          return 'Maintenance task marked as completed in Asana';
        default:
          return 'Conversation action performed';
      }
    default:
      return 'No activity yet';
  }
};
