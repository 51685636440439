import {ResponsiveDialogClose} from '@/components/dialogs/ResponsiveDialogClose';
import {useArchiveMarketplaceItemMutation} from '@/generated/graphql';
import {useLogEvent} from '@/hooks/useLogEvent/useLogEvent';
import {LoadingButton} from '@mui/lab';
import {Box, DialogContent, Input, TextField, Typography} from '@mui/material';
import {ResponsiveDialog} from 'common';
import {useSnackbar} from 'notistack';
import {useState} from 'react';
import {useParams} from 'react-router-dom';

type Props = {
  open: boolean;
  onClose: () => void;
};

export const ArchiveMarketplaceItemDialog = (props: Props) => {
  const {id} = useParams<{id: string}>();
  const logEvent = useLogEvent();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [reason, setReason] = useState('');
  const [archiveItem, {loading}] = useArchiveMarketplaceItemMutation({
    variables: {
      input: {
        id,
        archivedReason: reason,
      },
    },
  });
  const handleClose = () => {
    setErrorMessage(null);
    setReason('');
    props.onClose();
  };

  const {enqueueSnackbar} = useSnackbar();

  const archive = async () => {
    try {
      const response = await archiveItem();
      const errors = response.data?.archiveMarketplaceItem?.errors;
      if (errors && errors[0]) {
        setErrorMessage(errors[0]?.messages[0]);
      } else {
        enqueueSnackbar('Marketplace item archived', {
          variant: 'success',
        });
        logEvent({
          name: 'Marketplace Dialog - Marketplace Item Archived',
          data: {marketplaceItemId: id ?? '', archiveReason: reason},
        });
        handleClose();
      }
    } catch {
      enqueueSnackbar(
        'There was an error archiving the item. Please try again.',
        {
          variant: 'error',
        },
      );
    }
  };

  return (
    <ResponsiveDialog
      open={props.open}
      onClose={handleClose}
      alwaysHideIntercom
    >
      <ResponsiveDialogClose onClose={handleClose} />
      <DialogContent sx={{mx: {sm: 2}}}>
        <Typography variant="h3" textAlign="center" my={2}>
          Archive Marketplace Item
        </Typography>
        <Typography
          variant="body1"
          mb={4}
          textAlign="center"
          color="text.secondary"
        >
          Please provide a reason for archiving this item.
        </Typography>
        <TextField
          label="Reason"
          multiline
          rows={3}
          fullWidth
          value={reason}
          onChange={e => setReason(e.target.value)}
        />
        {errorMessage && (
          <Typography color="error" variant="body2" mb={2}>
            {errorMessage}
          </Typography>
        )}
        <LoadingButton
          loading={loading}
          onClick={archive}
          variant="contained"
          color="secondary"
          fullWidth
          disabled={!reason}
        >
          Archive
        </LoadingButton>
      </DialogContent>
    </ResponsiveDialog>
  );
};
