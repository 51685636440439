import {useSideBarAccordion} from '@/contexts/SideBarAccordionContext';
import {useFeatures} from '@/hooks/useFeatures/useFeatures';
import {useLogAppUse} from '@/hooks/useLogAppUse';
import {useTrackPageView} from '@/hooks/useTrackPageView';
import {SentryRoutes} from '@/main';
import {MarketplacePage} from '@/pages/ENT/Marketplace/MarketplacePage/MarketplacePage';
import React, {useEffect} from 'react';
import lazyWithPreload from 'react-lazy-with-preload';
import {Route} from 'react-router-dom';
import {HomePackagesPage} from '../../pages/ENT/Packages/HomePackagesPage/HomePackagesPage';
import {ParamsToUUIDNavigator} from '../ParamsToUUIDNavigator';
import {useReferencingEnabled} from './useReferencingEnabled/useReferencingEnabled';

const ConversationsPage = lazyWithPreload(
  () => import('../../pages/ENT/Conversations/Conversations'),
);
const UpdatesPage = lazyWithPreload(() => import('../../pages/ENT/Updates'));
const SurveysPage = lazyWithPreload(() => import('../../pages/ENT/Surveys'));
const DashboardPage = lazyWithPreload(
  () => import('../../pages/ENT/Dashboard/DashboardPage/DashboardPage'),
);
const CommunitiesPage = lazyWithPreload(
  () => import('../../pages/ENT/Communities'),
);
const CommunityDetailsPage = lazyWithPreload(() =>
  import(
    '../../pages/ENT/Communities/CommunityDetailsPage/CommunityDetailsPage'
  ).then(module => ({
    default: module.CommunityDetailsPage,
  })),
);
const SideBar = lazyWithPreload(() => import('../../layout/SideBar'));
const SatisfactionScores = lazyWithPreload(
  () => import('../../pages/ENT/Dashboard/SatisfactionScoresPage'),
);
const DownloadRate = lazyWithPreload(
  () => import('../../pages/ENT/Dashboard/DownloadRatePage'),
);
const ResidentEngagement = lazyWithPreload(
  () => import('../../pages/ENT/Dashboard/ResidentEngagement'),
);
const ResponseTime = lazyWithPreload(
  () => import('../../pages/ENT/Dashboard/ResponseTimePage'),
);
const ContactPage = lazyWithPreload(
  () => import('../../pages/ENT/Contacts/ContactDetailsPage'),
);
const Contacts = lazyWithPreload(() =>
  import('../../pages/ENT/Contacts/ContactsPage').then(module => ({
    default: module.ContactsPage,
  })),
);
const VerificationPage = lazyWithPreload(() =>
  import('../../pages/ENT/Applicants/ApplicantsPage').then(module => ({
    default: module.ApplicantsPage,
  })),
);
const Profile = lazyWithPreload(() => import('../../pages/ENT/ProfilePage'));
const Packages = lazyWithPreload(
  () => import('../../pages/ENT/Packages/PackagesPage'),
);
const Keys = lazyWithPreload(() =>
  import('../../pages/ENT/Keys/KeysPage').then(module => ({
    default: module.KeysPage,
  })),
);
const CommunityProductsPage = lazyWithPreload(
  () => import('../../pages/ENT/CommunityProducts'),
);
const HomePage = lazyWithPreload(
  () => import('../../pages/ENT/Homes/HomeDetailsPage'),
);
const Homes = lazyWithPreload(() =>
  import('../../pages/ENT/Homes').then(module => ({
    default: module.HomesPage,
  })),
);
const MembersPage = lazyWithPreload(() => import('../../pages/Admin/Members'));
const SettingsPage = lazyWithPreload(
  () => import('../../pages/Admin/Settings'),
);
const Documents = lazyWithPreload(() =>
  import('../../pages/ENT/Documents/DocumentsPage').then(module => ({
    default: module.DocumentsPage,
  })),
);
const PageNotFound = lazyWithPreload(() => import('../../pages/ENT/404Page'));
const Layout = lazyWithPreload(() => import('../../layout'));
const Events = lazyWithPreload(() => import('../../pages/ENT/Events'));
const Payments = lazyWithPreload(() => import('../../pages/ENT/Payments'));
const Amenities = lazyWithPreload(
  () => import('../../pages/ENT/Amenities/AmenitiesListPage'),
);
const Referencing = lazyWithPreload(
  () => import('../../pages/ENT/Referencing'),
);
const ApplicationDetails = lazyWithPreload(() =>
  import('../../pages/ENT/Referencing/ApplicationDetailsPage').then(module => ({
    default: module.ApplicationDetailsPage,
  })),
);
const ApplicantDetails = lazyWithPreload(() =>
  import('../../components/Referencing/ApplicantDetails/ApplicantDetails').then(
    module => ({
      default: module.ApplicantDetails,
    }),
  ),
);
const VisitorsPage = lazyWithPreload(() =>
  import('../../pages/ENT/Visitors/VisitorsPage').then(module => ({
    default: module.VisitorsPage,
  })),
);
const GroupsPage = lazyWithPreload(() =>
  import('../../pages/ENT/Groups/GroupsPage').then(module => ({
    default: module.GroupsPage,
  })),
);
const HomePagesPage = lazyWithPreload(() =>
  import('../../pages/ENT/Packages/HomePackagesPage/HomePackagesPage').then(
    module => ({
      default: module.HomePackagesPage,
    }),
  ),
);

const ComplianceBlocksPage = lazyWithPreload(() =>
  import('../../pages/ENT/Compliance/Blocks/ComplianceBlocksPage').then(
    module => ({
      default: module.ComplianceBlocksPage,
    }),
  ),
);

const BlocksPage = lazyWithPreload(() =>
  import('../../pages/ENT/Blocks/BlocksPage').then(module => ({
    default: module.BlocksPage,
  })),
);

const Requirements = lazyWithPreload(() =>
  import('../../pages/ENT/Compliance/Requirements/Requirements').then(
    module => ({
      default: module.Requirements,
    }),
  ),
);

const componentsToPreload = [
  ConversationsPage,
  UpdatesPage,
  SurveysPage,
  DashboardPage,
  CommunitiesPage,
  CommunityDetailsPage,
  SideBar,
  SatisfactionScores,
  DownloadRate,
  ResidentEngagement,
  ResponseTime,
  Contacts,
  ContactPage,
  VerificationPage,
  Profile,
  Packages,
  Keys,
  CommunityProductsPage,
  HomePage,
  Homes,
  MembersPage,
  SettingsPage,
  Documents,
  PageNotFound,
  Layout,
  Events,
  Payments,
  Amenities,
  Referencing,
  ApplicationDetails,
  ApplicantDetails,
  VisitorsPage,
  GroupsPage,
  HomePagesPage,
  ComplianceBlocksPage,
  Requirements,
  BlocksPage,
];

type Props = {
  isAdmin?: boolean;
};

const ManagerRoot: React.FC<Props> = ({isAdmin}) => {
  useLogAppUse();
  const [features] = useFeatures();
  useTrackPageView();
  const referencingEnabled = useReferencingEnabled();
  const {toggleAll} = useSideBarAccordion();

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'e' && e.metaKey) {
        toggleAll();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [toggleAll]);

  useEffect(() => {
    componentsToPreload.forEach(component => component.preload());
  }, []);

  return (
    <SentryRoutes>
      <Route element={<Layout />}>
        <Route path="/" element={<DashboardPage />} />
        <Route path="/events/:id?/:action?" element={<Events />} />
        <Route path="/documents/:id?/:action?" element={<Documents />} />
        <Route
          path="/updates/:id?/:action?/:communityId?"
          element={<UpdatesPage />}
        />
        <Route path="/surveys/:id?/:action?" element={<SurveysPage />} />
        <Route
          path="/messages/:conversationId?"
          element={<ConversationsPage />}
        />
        <Route
          path="/communities/:communityId/:tab?/:id?/:action?"
          element={<CommunityDetailsPage />}
        />
        <Route path="/communities/" element={<CommunitiesPage />} />
        <Route path="/dashboard" element={<DashboardPage />} />
        <Route path="/dashboard/download-rate" element={<DownloadRate />} />
        <Route
          path="/dashboard/resident-engagement"
          element={<ResidentEngagement />}
        />
        <Route
          path="/dashboard/satisfaction-scores"
          element={<SatisfactionScores />}
        />
        <Route path="/dashboard/response-time" element={<ResponseTime />} />

        <Route
          path="/contact-profiles/:contactId?/:conversationId?"
          element={<ContactPage />}
        />
        <Route path="/contact-profiles/" element={<Contacts />} />
        <Route path="/verification/:id?" element={<VerificationPage />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/packages/:packageId?" element={<Packages />} />
        <Route
          path="/keys/:keyId?/:action?/:keyholderId?/:action?"
          element={<Keys />}
        />
        <Route
          path="/community-products/:id?"
          element={<CommunityProductsPage />}
        />
        <Route
          path="/homes/:homeId?/packages/:packageId?"
          element={<HomePackagesPage />}
        />
        <Route path="/homes/:homeId" element={<HomePage />} />
        <Route path="/homes" element={<Homes />} />
        {features.LeaseholderPayments && (
          <Route path="/payments" element={<Payments />} />
        )}
        <Route path="/groups/:id?/:action?" element={<GroupsPage />} />

        <Route path="/facilities/:id?/:action?" element={<Amenities />} />
        {referencingEnabled && (
          <>
            <Route
              path="/referencing/application/:id"
              element={
                <ParamsToUUIDNavigator parameterNames={['id']}>
                  <ApplicationDetails />
                </ParamsToUUIDNavigator>
              }
            />
            <Route
              path="/tenant-reference-report/:tenantReferenceUUID"
              element={
                <ParamsToUUIDNavigator parameterNames={['tenantReferenceUUID']}>
                  <ApplicantDetails />
                </ParamsToUUIDNavigator>
              }
            />
            <Route path="/referencing" element={<Referencing />} />
          </>
        )}
        <Route path="/blocks/:id?" element={<BlocksPage />} />
        <Route path="/compliance/blocks" element={<ComplianceBlocksPage />} />
        <Route path="/compliance/requirements" element={<Requirements />} />
        {isAdmin && <Route path="/members/:id?" element={<MembersPage />} />}
        {isAdmin && <Route path="/settings/:id?" element={<SettingsPage />} />}
        <Route
          path="/visitors/qr-codes/:communityId"
          element={<VisitorsPage />}
        />
        <Route path="/visitors/qr-codes" element={<VisitorsPage />} />
        <Route path="/visitors/:id?" element={<VisitorsPage />} />
        <Route path="/marketplace/:id?" element={<MarketplacePage />} />
        <Route path="*" element={<PageNotFound />} />
      </Route>
    </SentryRoutes>
  );
};

export default ManagerRoot;
