import React from 'react';
import {makeStyles} from '@mui/styles';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import IconButton from '@mui/material/IconButton';
import {colors} from '../theme/colors';

export interface DialogCloseProps {
  onClose: () => void;
  positionLeft?: boolean;
}

const useStyles = makeStyles({
  closeButton: {
    position: 'absolute',
    top: 16,
    border: `1px solid ${colors.alto}`,
    borderRadius: 4,
    padding: 3,
    zIndex: 1,
    backgroundColor: colors.white,
  },
  rightClose: {
    right: 16,
  },
  leftClose: {
    left: 16,
  },
});

export const DialogClose: React.FC<DialogCloseProps> = props => {
  const classes = useStyles();

  return (
    <IconButton
      aria-label="close"
      className={`${classes.closeButton} ${
        props.positionLeft ? classes.leftClose : classes.rightClose
      }`}
      onClick={props.onClose}
      size="large">
      <CloseRoundedIcon />
    </IconButton>
  );
};
