import {
  Card,
  CardProps,
  CardContent,
  IconButton,
  Typography,
  Box,
} from '@mui/material';
import {Article, Close} from '@mui/icons-material';
import {makeStyles} from '@mui/styles';
import {CardActions} from '@mui/material';
import FileOverlay from './FileBackdrop';
import React, {useState} from 'react';
import {isImage, isUrlPdf} from '@/pages/ENT/Communities/utils';
import classNames from 'classnames';
import {Document, Thumbnail} from 'react-pdf';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    '&:hover': {
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
      cursor: 'pointer',
    },
  },
  img: {
    height: 112,
    width: 112,
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    objectFit: 'cover',
  },
  imgOnly: {
    width: 'auto',
    maxWidth: '100%',
  },
  placeholder: {
    height: 112,
    width: 112,
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    color: theme.palette.grey[500],
    backgroundColor: theme.palette.grey[300],
    padding: 16,
    [theme.breakpoints.down('sm')]: {
      height: 'fit-content',
    },
  },
  summaryText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

type FileThumbnailProps = {
  file: File | string;
  onClose?: () => void;
  fullWidth?: boolean;
  imageOnly?: boolean;
} & CardProps;

const FileThumbnail = ({
  file,
  onClose,
  fullWidth,
  sx,
  imageOnly,
}: FileThumbnailProps) => {
  const styles = useStyles();

  const [open, setOpen] = useState(false);

  const fileName = React.useMemo(() => {
    if (!(file instanceof File)) {
      const splitUrl = [...file.split('/')];
      const last = splitUrl.pop()?.split('?')[0];
      return last;
    } else {
      return file.name.split('?').pop()?.toLowerCase();
    }
  }, [file]);

  const extension = [...(fileName?.split('.') || [])].pop()?.toLowerCase();

  const showPdf =
    isUrlPdf(fileName) &&
    !(file instanceof File) &&
    file?.includes(import.meta.env.VITE_BACKEND_FILE_ROOT);

  return (
    <>
      <Card
        className={styles.root}
        sx={{
          ...sx,
          width: fullWidth ? '100%' : 'fit-content',
          maxWidth: fullWidth
            ? '100%'
            : {
                xs: '100%',
                sm: '100%',
                md: '75%',
                lg: '50%',
              },
        }}
        onClick={() => setOpen(true)}
      >
        {isImage(extension || '') && (
          <img
            src={file instanceof File ? URL.createObjectURL(file) : file}
            alt={fileName}
            className={classNames(styles.img, {[styles.imgOnly]: imageOnly})}
          />
        )}
        {showPdf && (
          <Document
            file={file}
            loading={() => <Box className={styles.placeholder} />}
          >
            <Thumbnail
              pageNumber={1}
              height={112}
              loading={() => <Box className={styles.placeholder} />}
            />
          </Document>
        )}
        {!isImage(extension || '') && !showPdf && (
          <Article
            fontSize="large"
            className={styles.placeholder}
            sx={{
              display: 'flex',
              alignSelf: 'center',
              justifySelf: 'center',
            }}
          />
        )}
        {!imageOnly && (
          <CardContent
            sx={{
              overflow: 'hidden',
              alignSelf: 'center',
              marginLeft: 2,
            }}
          >
            <Typography className={styles.summaryText} variant="h5">
              {fileName}
            </Typography>
          </CardContent>
        )}
        {onClose && (
          <CardActions>
            <IconButton
              aria-label="previous"
              onClick={e => {
                e.stopPropagation();
                onClose();
              }}
            >
              <Close />
            </IconButton>
          </CardActions>
        )}
      </Card>
      <FileOverlay open={open} onClose={() => setOpen(false)} file={file} />
    </>
  );
};

export default FileThumbnail;
