// @ts-ignore
import React from 'react';
// @ts-ignore
import {InsertInvitation} from '@mui/icons-material';
// @ts-ignore
import {Box, Typography, useTheme} from '@mui/material';
//@ts-ignore
import {colors} from 'themes/colors';
// @ts-ignore
import {makeStyles} from '@mui/styles';
import {timeAgo} from '../formatters/dates';
// @ts-ignore
import {DateTime} from 'luxon';

export interface DateItemProps {
  date?: string;
  extraText?: string;
  color?: string;
  relative?: boolean;
}

const useStyles = makeStyles({
  dateIcon: {
    marginRight: 4,
  },
  text: {
    whiteSpace: 'nowrap',
  },
});
// @ts-ignore
export const DateItem: React.FC<DateItemProps> = props => {
  const styles = useStyles();

  const theme = useTheme();

  let dateText = '--';
  if (props.date) {
    dateText = props.relative
      ? timeAgo(props.date)
      : DateTime.fromISO(props.date).toFormat('dd/MM/yyyy');
  }

  return (
    // @ts-ignore
    <Box display="flex" alignItems="center">
      {/* 
      // @ts-ignore */}
      <InsertInvitation
        className={styles.dateIcon}
        fontSize="small"
        sx={{
          color: props.color || theme.palette.text.primary,
        }}
      />
      <Typography
        variant="body1"
        className={styles.text}
        sx={{
          color: props.color || theme.palette.text.primary,
        }}
      >
        {props.extraText}
        {dateText}
      </Typography>
    </Box>
  );
};
