import React from 'react';
import {Pill} from '@ark/web';
import {handleStatus} from '../formatters/conversations';

interface ConversationStatusPillProps {
  closedAt?: string | null;
  snoozedUntil?: string | null;
}

export const ConversationStatusPill = ({
  closedAt,
  snoozedUntil,
}: ConversationStatusPillProps) => {
  const status = handleStatus(closedAt, snoozedUntil);
  return <Pill color={status.color} label={status.label} />;
};
