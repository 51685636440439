import {Skeleton, Typography, TypographyProps} from '@mui/material';
import React from 'react';

type Props = TypographyProps & {
  loading?: boolean;
};

export const SideDialogTitle: React.FC<Props> = ({
  loading,
  children,
  ...props
}) => {
  if (loading) {
    return <Skeleton height="3em" width="20em" animation="wave" />;
  }

  return (
    <Typography
      variant="h1"
      maxWidth="100%"
      {...props}
      overflow="hidden"
      textOverflow="ellipsis"
    >
      {children}
    </Typography>
  );
};
