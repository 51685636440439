import {useGetClientPaymentsEnabledQuery} from '@/generated/graphql';
import {useFeatures} from '@/hooks/useFeatures/useFeatures';
import {CategoryItem} from '@/layout/SideBar/SideBarAccordion';
import {useUserCommunitySettings} from '@ark/react';
import {
  AccountCircle,
  AddHomeWork,
  ApartmentRounded,
  Bolt,
  CheckCircle,
  CreditCard,
  Dashboard,
  Draw,
  FindInPageRounded,
  HomeSharp,
  Inventory,
  Key,
  MapsHomeWork,
  PeopleRounded,
  RoomService,
  Gavel,
  ViewCompact,
  Checklist,
} from '@mui/icons-material';
import SideBarContent, {
  SideBarItem,
  UserSideBarContentProps,
} from '../../layout/SideBar/SideBarContent';
import {
  documentsItem,
  eventsItem,
  facilitiesItem,
  marketplaceItem,
  messagesItem,
  newsFeedItem,
  paymentsItem,
  productsItem,
  surveysItem,
} from '../sideBarItems';
import {useReferencingEnabled} from './useReferencingEnabled/useReferencingEnabled';

type ManagerSideBarContentProps = UserSideBarContentProps;

export const ManagerSideBarContent = (props: ManagerSideBarContentProps) => {
  const [features] = useFeatures();
  const {data} = useGetClientPaymentsEnabledQuery();
  const {mergedSettings, loading} = useUserCommunitySettings([
    'manager',
    'concierge',
  ]);

  const paymentsEnabled = data?.getClient?.paymentsEnabled;
  const referencingEnabled = useReferencingEnabled();

  if (loading) {
    return null;
  }

  const engagementCategoryItem: CategoryItem = {
    category: 'engagement',
    icon: <Bolt fontSize="small" />,
    items: [],
  };

  const onboardingCategoryItem: CategoryItem = {
    category: 'onboarding',
    icon: <AddHomeWork fontSize="small" />,
    items: [],
  };

  const communitiesCategoryItem: CategoryItem = {
    category: 'communities',
    icon: <MapsHomeWork fontSize="small" />,
    items: [
      {
        url: '/communities',
        label: 'Overview',
        icon: <ApartmentRounded fontSize="small" />,
      },
      {
        url: '/homes',
        label: 'Homes',
        icon: <HomeSharp fontSize="small" />,
      },
      {
        url: '/contact-profiles',
        label: 'Contacts',
        icon: <AccountCircle fontSize="small" />,
      },
      {
        url: '/groups',
        label: 'Groups',
        icon: <PeopleRounded fontSize="small" />,
      },
      {
        url: '/verification',
        label: 'Applicants',
        icon: <CheckCircle fontSize="small" />,
      },
    ],
  };

  const complianceCategoryItem: CategoryItem = {
    category: 'compliance',
    icon: <Gavel fontSize="small" />,
    items: [
      {
        url: '/compliance/blocks',
        label: 'Blocks',
        icon: <ViewCompact fontSize="small" />,
      },
      {
        url: '/compliance/requirements',
        label: 'Requirements',
        icon: <Checklist fontSize="small" />,
      },
    ],
  };

  const items: (SideBarItem | CategoryItem)[] = [
    {
      url: '/dashboard',
      label: 'Dashboard',
      icon: <Dashboard fontSize="small" />,
    },
    communitiesCategoryItem,
    engagementCategoryItem,
    {
      category: 'concierge',
      icon: <RoomService fontSize="small" />,
      items: [
        {
          url: '/packages',
          label: 'Packages',
          icon: <Inventory fontSize="small" />,
        },
        {
          url: '/keys',
          label: 'Keys',
          icon: <Key fontSize="small" />,
        },
        {
          url: '/visitors',
          label: 'Visitors',
          icon: <Draw fontSize="small" />,
        },
      ],
    },
    onboardingCategoryItem,
  ];

  if (mergedSettings?.newsfeed) {
    engagementCategoryItem.items?.push(newsFeedItem);
  }
  if (mergedSettings?.messages) {
    engagementCategoryItem.items?.push(messagesItem);
  }
  if (mergedSettings?.surveys) {
    engagementCategoryItem.items?.push(surveysItem);
  }
  if (mergedSettings?.documents) {
    engagementCategoryItem.items?.push(documentsItem);
  }
  if (mergedSettings?.events) {
    engagementCategoryItem.items?.push(eventsItem);
  }
  if (mergedSettings?.facilities) {
    engagementCategoryItem.items?.push(facilitiesItem);
  }
  if (mergedSettings?.marketplace) {
    engagementCategoryItem.items?.push(marketplaceItem);
  }

  if (mergedSettings?.compliance) {
    communitiesCategoryItem.items?.push({
      url: '/blocks',
      label: 'Blocks',
      icon: <ViewCompact fontSize="small" />,
    });
  }

  if (
    (features.CommunityProducts && paymentsEnabled) ||
    features.LeaseholderPayments
  ) {
    const paymentsCategoryItem: CategoryItem = {
      category: 'payments',
      icon: <CreditCard fontSize="small" />,
      items: [],
    };
    items.push(paymentsCategoryItem);
    if (
      features.CommunityProducts &&
      paymentsEnabled &&
      mergedSettings.products
    ) {
      paymentsCategoryItem.items.push(productsItem);
    }

    if (features.LeaseholderPayments && mergedSettings.accounts) {
      paymentsCategoryItem.items.push(paymentsItem);
    }
  }

  if (referencingEnabled) {
    onboardingCategoryItem.items.push({
      url: '/referencing',
      label: 'Referencing',
      icon: <FindInPageRounded fontSize="small" />,
    });
  }

  if (mergedSettings?.compliance) {
    const engagementIndex = items.findIndex(
      item => item === engagementCategoryItem,
    );
    items.splice(engagementIndex + 2, 0, complianceCategoryItem);
  }

  return (
    <SideBarContent items={items} onClose={props.onClose}>
      {props.children}
    </SideBarContent>
  );
};
