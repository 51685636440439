import {useGetClientPaymentsEnabledQuery} from '@/generated/graphql';
import {useFeatures} from '@/hooks/useFeatures/useFeatures';
import {useUserCommunitySettings} from '@ark/react';
import {
  ArtTrackRounded,
  Chat,
  Draw,
  EventRounded,
  HomeSharp,
  Inventory,
  Key,
  MenuBookRounded,
  PeopleRounded,
  ShoppingCartRounded,
  Storefront,
} from '@mui/icons-material';
import SideBarContent, {
  UserSideBarContentProps,
} from '../../layout/SideBar/SideBarContent';

type ConciergeSideBarContentProps = UserSideBarContentProps;

export const ConciergeSideBarContent = (
  props: ConciergeSideBarContentProps,
) => {
  const [features] = useFeatures();
  const {data} = useGetClientPaymentsEnabledQuery();
  const {mergedSettings, loading} = useUserCommunitySettings(['concierge']);

  const paymentsEnabled = data?.getClient?.paymentsEnabled;

  if (loading) {
    return null;
  }

  let items = [
    {
      url: '/updates',
      label: 'Updates',
      icon: <ArtTrackRounded fontSize="small" />,
    },
    {
      url: '/homes',
      label: 'Homes',
      icon: <HomeSharp fontSize="small" />,
    },
    {
      url: '/messages',
      label: 'Messages',
      icon: <Chat fontSize="small" />,
    },
    {
      url: '/contact-profiles',
      label: 'Contacts',
      icon: <PeopleRounded fontSize="small" />,
    },
    {
      url: '/packages',
      label: 'Packages',
      icon: <Inventory fontSize="small" />,
    },
    {
      url: '/events',
      label: 'Events',
      icon: <EventRounded fontSize="small" />,
    },

    {
      url: '/facilities',
      label: 'Facilities',
      icon: <MenuBookRounded fontSize="small" />,
    },
    {
      url: '/marketplace',
      label: 'Marketplace',
      icon: <Storefront fontSize="small" />,
    },

    {
      url: '/keys',
      label: 'Keys',
      icon: <Key fontSize="small" />,
    },
    ...(features.CommunityProducts && paymentsEnabled
      ? [
          {
            url: '/community-products',
            label: 'Products',
            icon: <ShoppingCartRounded fontSize="small" />,
          },
        ]
      : []),
    {
      url: '/visitors',
      label: 'Visitors',
      icon: <Draw fontSize="small" />,
    },
  ];

  if (!mergedSettings.newsfeed) {
    items = [...items.filter(item => item.url !== '/updates')];
  }
  if (!mergedSettings.messages) {
    items = [...items.filter(item => item.url !== '/messages')];
  }
  if (!mergedSettings.surveys) {
    items = [...items.filter(item => item.url !== '/surveys')];
  }
  if (!mergedSettings.documents) {
    items = [...items.filter(item => item.url !== '/documents')];
  }
  if (!mergedSettings.events) {
    items = [...items.filter(item => item.url !== '/events')];
  }
  if (!mergedSettings.facilities) {
    items = [...items.filter(item => item.url !== '/facilities')];
  }
  if (!mergedSettings.marketplace) {
    items = [...items.filter(item => item.url !== '/marketplace')];
  }

  return (
    <SideBarContent items={items} onClose={props.onClose}>
      {props.children}
    </SideBarContent>
  );
};
