import {
  Autocomplete,
  AutocompleteProps,
  Box,
  CircularProgress,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import {makeStyles} from '@mui/styles';
import React, {SyntheticEvent, useState} from 'react';

export type SearchSelectValueSingle<T = string> = {value: T; label: string};
export type SearchSelectValue<T = string> = SearchSelectValueSingle<T>[];

interface SearchSelectProps<T = string>
  //@ts-ignore
  extends Omit<AutocompleteProps, 'value'> {
  options: SearchSelectValue<T>;
  label: string;
  value: SearchSelectValue<T>;
  setValue: (value: SearchSelectValue<T>) => void;
  onSearch?: (value: string) => void;
  onListScroll?: (e: SyntheticEvent) => void;
  multiple?: boolean;
  fullWidth?: boolean;
  getOptionDisabled?: (option: SearchSelectValue<T>[0]) => boolean;
  disabled?: boolean;
  error?: boolean;
  loading?: boolean;
  disabledTooltip?: string;
}

const useStyles = makeStyles(theme => ({
  autocomplete: {
    minWidth: 175,
  },
  tag: {
    maxWidth: 'calc(100% - 24px)!important',
  },
  listItem: {
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

export const SearchSelect = <T,>(props: SearchSelectProps<T>) => {
  const [isFocused, setIsFocused] = useState(false);
  const styles = useStyles();
  const [search, setSearch] = React.useState<string | undefined>();

  const options = props.options.filter(option =>
    option.label.toLowerCase().includes(search?.toLowerCase() || ''),
  );

  return (
    <Autocomplete
      {...props}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      sx={{
        '& .MuiInputBase-root': isFocused
          ? {
              height: 'auto',
              overflow: 'scroll',
            }
          : {
              height: '40px',
              overflow: 'hidden',
            },
      }}
      fullWidth={props.fullWidth}
      id={props.label}
      classes={{
        tag: styles.tag,
      }}
      size="small"
      multiple
      limitTags={1}
      className={styles.autocomplete}
      options={options}
      value={props.value}
      onChange={(e, value) => {
        if (props.multiple) {
          props.setValue(value as SearchSelectValue<T>);
        } else {
          if (value.length > 0) {
            const lastValue = value[value.length - 1];
            props.setValue([lastValue]);
          } else {
            props.setValue([]);
          }
        }
      }}
      disabled={props.disabled}
      getOptionDisabled={props.getOptionDisabled}
      renderOption={(optionProps, option) => {
        const isDisabled = optionProps['aria-disabled'];
        return (
          <Tooltip
            title={isDisabled && props.disabledTooltip}
            placement="bottom"
          >
            <Box>
              <MenuItem {...optionProps}>
                <Typography>{option.label}</Typography>
              </MenuItem>
            </Box>
          </Tooltip>
        );
      }}
      disableClearable={!props.multiple}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      getOptionLabel={option => option.label}
      renderInput={params => {
        params.inputProps.value = search;
        params.InputProps.endAdornment = props.loading ? (
          <CircularProgress sx={{position: 'absolute', right: 12}} size={20} />
        ) : (
          params.InputProps.endAdornment
        );
        return (
          <TextField
            onChange={e => {
              const newInputValue = e.target.value;
              setSearch(newInputValue);
              props.onSearch?.(newInputValue);
            }}
            onKeyDown={event => {
              if (event.key === 'Backspace') {
                event.stopPropagation();
              }
            }}
            error={props.error}
            {...params}
            label={props.label}
            sx={{marginBottom: '4px'}}
          />
        );
      }}
      ListboxProps={{
        style: {
          maxHeight: '300px',
        },
        onScroll: props.onListScroll,
      }}
    />
  );
};
