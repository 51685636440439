import Timeline from '@mui/lab/Timeline';
import {timelineItemClasses} from '@mui/lab/TimelineItem';
import PackageTimelineItem from './PackageTimelineItem';

type PackageTimelineProps = {
  scannedInOn: string;
  scannedInBy: string;
  imageUrl?: string | null;
  reminders: {
    id: string;
    createdAt: string;
    name: string;
  }[];
  collectedOn?: string;
  collectedBy?: string;
};

const PackageTimeline = ({
  scannedInOn,
  scannedInBy,
  imageUrl,
  reminders,
  collectedOn,
  collectedBy,
}: PackageTimelineProps) => {
  return (
    <Timeline
      sx={{
        margin: 0,
        padding: 0,
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
      }}>
      {collectedOn && (
        <PackageTimelineItem
          headerText="Collected"
          dateText={collectedOn}
          bodyText="This package was collected by "
          boldBodyText={collectedBy}
        />
      )}
      {reminders?.map(reminder => {
        return (
          <PackageTimelineItem
            key={reminder.id}
            headerText="Reminder"
            dateText={reminder.createdAt}
            bodyText="A reminder to pick up this package was sent to the resident by "
            boldBodyText={reminder.name}
          />
        );
      })}
      <PackageTimelineItem
        headerText={"We've got it"}
        bodyText="We are holding onto this package for the resident"
      />
      <PackageTimelineItem
        headerText="Delivered"
        dateText={scannedInOn}
        bodyText="This package was delivered and scanned in by "
        boldBodyText={scannedInBy}
        imageUrl={imageUrl}
      />
    </Timeline>
  );
};

export default PackageTimeline;
