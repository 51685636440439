import React from 'react';
import {Box, Typography, Grid, useTheme} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {colors} from '../../theme/colors';
import {DateItem} from '../DateItem';
import {ConversationStatusPill} from '../ConversationStatusPill';
import {
  PriorityHighRounded,
  ArrowCircleRightRounded,
  TurnLeftRounded,
} from '@mui/icons-material';
import {ConversationListItemData, Sender} from './Conversations';
import {messageTextFromEvent} from '../../formatters/conversations';

import {track} from '@amplitude/analytics-browser';

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: theme.palette.background.paper,
    cursor: 'pointer',
    border: `1px solid ${theme.palette.divider}`,
    transition: 'transform 0.2s',
    boxShadow: 'none',
    borderRadius: '4px',
    '&:before': {
      display: 'none',
    },
    '&:hover': {
      borderColor: theme.palette.grey[400],
      cursor: 'pointer',
      transform: 'scale(1.01)',
      transition: 'transform 0.2s',
      boxShadow: '0px 0px 4px 0px rgba(0,0,0,0.05)',
    },
    marginBottom: 16,
    padding: '12px',
  },
  upperContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  topRightContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
  },
  addressContainer: {
    marginBottom: '6px',
  },
  messageContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  replySentIcon: {
    margin: '2px',
    display: 'flex',
    alignItems: 'center',
    padding: '5px',
    borderRadius: '50%',
    opacity: 0.5,
    backgroundColor: colors.moreFadedText,
    boxShadow: 'none',
  },
}));

export type ConversationListItemProps = {
  onClick?: () => void;
  data: ConversationListItemData;
  showUrgencySymbol?: boolean;
  showSubtitle?: boolean;
  snoozedStatus?: boolean;
  sender: Sender;
};

export const ConversationListItem: React.FC<ConversationListItemProps> = ({
  onClick,
  data,
  showUrgencySymbol,
  showSubtitle,
  snoozedStatus,
  sender,
}: ConversationListItemProps) => {
  const styles = useStyles();
  const theme = useTheme();
  const {
    conversationId,
    latestEvent,
    userName,
    isUnread,
    homeName,
    urgency,
    subject,
    closedAt,
    snoozedUntil,
  } = data;

  const messageText = React.useMemo(() => {
    return messageTextFromEvent(latestEvent, userName);
  }, [latestEvent, userName]);

  const latestEventIsFromResident =
    (latestEvent?.messageSender || latestEvent?.mediaSender) === 'RE';
  const lastEventSentByMe =
    sender === 'Resident'
      ? latestEventIsFromResident
      : !latestEventIsFromResident;

  return (
    <Box
      className={styles.container}
      onClick={() => {
        track('View Message', {
          'Conversation id': conversationId,
          Subject: subject,
        });
        onClick && onClick();
      }}
    >
      <Box className={styles.upperContainer}>
        <Typography variant="h2" noWrap padding={'2px'}>
          {subject}
        </Typography>
        <Box className={styles.topRightContainer}>
          {showUrgencySymbol && urgency === 'High' && (
            <PriorityHighRounded sx={{color: colors.red}} />
          )}
          <ConversationStatusPill
            closedAt={closedAt}
            snoozedUntil={snoozedStatus ? snoozedUntil : undefined}
          />
        </Box>
      </Box>
      {showSubtitle && (
        <Box className="addressContainer">
          <Typography
            variant="body1"
            sx={{color: theme.palette.text.primary}}
            paddingLeft={'2px'}
          >
            <strong>
              {userName}
              {'   |   '}
              {homeName}
            </strong>
          </Typography>
        </Box>
      )}

      <Grid container spacing={2}>
        <Grid item xs={12} md={9} className={styles.messageContainer}>
          {lastEventSentByMe ? (
            <Box className={styles.replySentIcon}>
              <TurnLeftRounded fontSize="small" sx={{color: colors.white}} />
            </Box>
          ) : (
            <ArrowCircleRightRounded
              fontSize="large"
              sx={isUnread ? {color: colors.brick} : {color: colors.treePoppy}}
            />
          )}
          <Typography
            variant="body1"
            noWrap
            fontWeight={isUnread && !lastEventSentByMe ? 'bold' : 'Regular'}
            padding={'4px 0px 2px'}
            sx={
              latestEvent?.__typename !== 'ConversationMessageType'
                ? {fontStyle: 'italic'}
                : {}
            }
          >
            {messageText}
          </Typography>
        </Grid>

        {latestEvent?.createdAt && (
          <Grid item xs={12} md={3} container>
            <Box display="flex" justifyContent="flex-end" flexGrow={1}>
              <DateItem date={latestEvent.createdAt} relative={true} />
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
