import {makeStyles} from '@mui/styles';
import {Box, BoxProps} from '@mui/system';

const useListItemStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    gap: 8,
    justifyContent: 'space-between',
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '4px',
    padding: theme.spacing(1.4),
    marginBottom: theme.spacing(2),
    width: '100%',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1),
    },
    transition: 'transform 0.2s',
  },

  hover: {
    '&:hover': {
      // backgroundColor: theme.palette.action.hover,
      borderColor: theme.palette.grey[400],
      cursor: 'pointer',
      transform: 'scale(1.01)',
      transition: 'transform 0.2s',
      boxShadow: '0px 0px 4px 0px rgba(0,0,0,0.05)',
    },
  },
}));

const ListItem: React.FC<BoxProps> = ({children, onClick, ...rest}) => {
  const styles = useListItemStyles();
  const {sx} = rest;
  return (
    <Box
      onClick={onClick}
      className={`${styles.root} ${onClick && styles.hover}`}
      sx={{...sx}}
      {...rest}
    >
      {children}
    </Box>
  );
};

export default ListItem;
