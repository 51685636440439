import {Box, Skeleton, Typography} from '@mui/material';
import {SxProps} from '@mui/system';

export type PillProps = {
  color: string | undefined;
  textColor?: string;
  label: string | undefined;
  sx?: SxProps;
  startIcon?: React.ReactNode;
  loading?: boolean;
  skeletonWidth?: number;
};

export const Pill = ({
  label,
  color,
  sx,
  textColor,
  startIcon,
  loading,
  skeletonWidth,
}: PillProps) => {
  if (loading || !label || !color) {
    return (
      <Skeleton
        variant="rectangular"
        height={18}
        width={skeletonWidth ?? 100}
        sx={{
          borderRadius: '4px',
          my: '3px',
        }}
      />
    );
  }

  return (
    <Box
      bgcolor={color}
      sx={{
        color: 'white',
        padding: '2px 6px',
        borderRadius: '3px',
        width: 'fit-content',
        height: 'fit-content',
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        ...sx,
      }}
    >
      {startIcon}
      <Typography fontWeight="500" color={textColor} noWrap>
        {label}
      </Typography>
    </Box>
  );
};
