import {
  AssessmentRounded,
  Chat,
  Description,
  EventRounded,
  MenuBookRounded,
  Newspaper,
  Payments,
  ShoppingCartRounded,
  Storefront,
} from '@mui/icons-material';
import {SideBarItem} from '../layout/SideBar/SideBarContent';

export const newsFeedItem: SideBarItem = {
  url: '/updates',
  label: 'Newsfeed',
  icon: <Newspaper fontSize="small" />,
};

export const messagesItem: SideBarItem = {
  url: '/messages',
  label: 'Messages',
  icon: <Chat fontSize="small" />,
};

export const surveysItem: SideBarItem = {
  url: '/surveys',
  label: 'Surveys',
  icon: <AssessmentRounded fontSize="small" />,
};

export const documentsItem: SideBarItem = {
  url: '/documents',
  label: 'Documents',
  icon: <Description fontSize="small" />,
};

export const eventsItem: SideBarItem = {
  url: '/events',
  label: 'Events',
  icon: <EventRounded fontSize="small" />,
};

export const facilitiesItem: SideBarItem = {
  url: '/facilities',
  label: 'Facilities',
  icon: <MenuBookRounded fontSize="small" />,
};

export const marketplaceItem: SideBarItem = {
  url: '/marketplace',
  label: 'Marketplace',
  icon: <Storefront fontSize="small" />,
};

export const productsItem: SideBarItem = {
  url: '/community-products',
  label: 'Products',
  icon: <ShoppingCartRounded fontSize="small" />,
};

export const paymentsItem: SideBarItem = {
  url: '/payments',
  label: 'Account',
  icon: <Payments fontSize="small" />,
};
