import React from 'react';
import {styled} from '@mui/material/styles';
import {Container, Box, Typography, ContainerProps} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {ArrowBack} from '@mui/icons-material';
import ScrollToTopOnMount from './ScrollToTopOnMount';
import {Breadcrumb, Breadcrumbs} from './Breadcrumbs';
import {transform} from 'lodash';

export type PageContainerProps = {
  backText?: string;
  handleGoBack?: () => void;
  children?: React.ReactNode;
  breadcrumbs?: Breadcrumb[];
} & ContainerProps;

const useStyles = makeStyles(theme => ({
  backButton: {
    display: 'flex',
    cursor: 'pointer',
    marginBottom: theme.spacing(2),
    color: theme.palette.grey[500],
    gap: theme.spacing(1),
    transition: 'transform 0.2s',
    '&:hover': {
      transform: 'translateX(-4px)',
      transition: 'transform 0.2s',
    },
  },
}));

const PageContainer = ({
  backText,
  handleGoBack,
  children,
  breadcrumbs,
  ...props
}: PageContainerProps) => {
  const styles = useStyles();
  return (
    <CustomBox maxWidth="md" {...props}>
      <ScrollToTopOnMount />
      {backText && (
        <Box className={styles.backButton} onClick={handleGoBack}>
          <ArrowBack fontSize="small" />
          <Typography variant="subtitle1">{backText}</Typography>
        </Box>
      )}
      {breadcrumbs && <Breadcrumbs breadcrumbs={breadcrumbs} />}
      {children || <div />}
      <Box height={48} />
    </CustomBox>
  );
};

export default PageContainer;

const CustomBox = styled(Container)(({theme}) => ({
  marginTop: 96,
  marginRight: 'auto',
  marginLeft: 'auto',
  position: 'relative',
  paddingBottom: theme.spacing(6),
  [theme.breakpoints.down('md')]: {
    marginTop: 25,
    paddingLeft: 0,
    paddingRight: 0,
    marginLeft: 0,
  },
}));
