import {ResponsiveDialogClose} from '@/components/dialogs/ResponsiveDialogClose';
import {useListPackageCollectorsForHomeQuery} from '@/generated/graphql';
import {edgesToNodes} from '@/utils/edgesToNodes';
import {HorizontalCenterCircularProgress, ResponsiveDialog} from 'common';
import {useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';
import {AddApplicant} from '../../PackageDialog/CollectionDialog/AddApplicant/AddApplicant';
import {CollectAllPackages} from './CollectAllPackages/CollectAllPackages';

export type CollectionDialogState = 'SELECT_COLLECTOR' | 'ADD_APPLICANT';

type Props = {
  open: boolean;
  onClose: () => void;
};

export const CollectAllPackagesDialog = ({open, onClose}: Props) => {
  const {homeId} = useParams() as {homeId: string};
  const [dialogState, setDialogState] =
    useState<CollectionDialogState>('SELECT_COLLECTOR');
  const [applicantUserId, setApplicantUserId] = useState<string | null>(null);

  const {data, loading} = useListPackageCollectorsForHomeQuery({
    variables: {
      id: homeId,
    },
  });

  const collectors = useMemo(() => {
    const applicantUsers = edgesToNodes(data?.home?.applications.edges).flatMap(
      application => application.applicant.user,
    );
    const residentUsers = data?.home?.activeResidency?.residents.map(
      resident => resident.user,
    );
    const collectors = [
      ...(applicantUsers || []),
      ...(residentUsers || []),
    ].map(user => ({
      label: user.firstName
        ? `${user.firstName} ${user?.lastName}`
        : user.email,
      value: user.id,
    }));
    return collectors;
  }, [data?.home]);

  return (
    <ResponsiveDialog open={open} onClose={onClose} alwaysHideIntercom>
      <ResponsiveDialogClose onClose={onClose} />
      {loading && (
        <HorizontalCenterCircularProgress sx={{marginY: 16, marginX: 20}} />
      )}
      {dialogState === 'SELECT_COLLECTOR' && data?.home?.address && (
        <CollectAllPackages
          address={data?.home?.address}
          setDialogState={setDialogState}
          onClose={onClose}
          applicantUserId={applicantUserId}
          collectors={collectors}
        />
      )}
      {dialogState === 'ADD_APPLICANT' && data?.home?.community?.id && (
        <AddApplicant
          homeId={homeId}
          communityId={data?.home?.community?.id}
          onBack={() => {
            setDialogState('SELECT_COLLECTOR');
          }}
          onComplete={applicant => {
            setApplicantUserId(applicant.user.id);
            setDialogState('SELECT_COLLECTOR');
          }}
        />
      )}
    </ResponsiveDialog>
  );
};
