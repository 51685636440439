import {ResponsiveDialogClose} from '@/components/dialogs/ResponsiveDialogClose';
import {usePackageQuery} from '@/generated/graphql';
import {ResponsiveDialog} from 'common';
import {useState} from 'react';
import {useParams} from 'react-router-dom';
import {AddApplicant} from './AddApplicant/AddApplicant';
import {CollectPackage} from './CollectPackage/CollectPackage';

export type CollectionDialogState = 'SELECT_COLLECTOR' | 'ADD_APPLICANT';

type Props = {
  open: boolean;
  onClose: () => void;
};

export const CollectionDialog = ({open, onClose}: Props) => {
  const [dialogState, setDialogState] =
    useState<CollectionDialogState>('SELECT_COLLECTOR');
  const [userId, setUserId] = useState<string | null>(null);
  const {packageId} = useParams() as {packageId: string};

  const {data} = usePackageQuery({
    variables: {
      id: packageId,
    },
    skip: !packageId,
  });

  const home = data?.package?.residency.home;

  return (
    <ResponsiveDialog open={open} onClose={onClose} alwaysHideIntercom>
      <ResponsiveDialogClose onClose={onClose} />
      {dialogState === 'SELECT_COLLECTOR' && (
        <CollectPackage
          setDialogState={setDialogState}
          onClose={onClose}
          userId={userId}
        />
      )}
      {dialogState === 'ADD_APPLICANT' && home?.id && home?.community?.id && (
        <AddApplicant
          onBack={() => {
            setDialogState('SELECT_COLLECTOR');
          }}
          onComplete={applicant => {
            setUserId(applicant.user.id);
            setDialogState('SELECT_COLLECTOR');
          }}
          homeId={home?.id}
          communityId={home?.community?.id}
        />
      )}
    </ResponsiveDialog>
  );
};
