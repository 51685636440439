export const colors: {[name: string]: string} = {
  codGrey: '#1A1A1A',
  black: '#000000',
  background: '#272726',
  fadedText: 'rgba(0, 0, 0, 0.56)',
  moreFadedText: 'rgb(0, 0, 0, 0.4)',
  mediumGrey: '#555',
  lightGrey: '#aaa',
  primary: '#F25757',
  concrete: '#F5F0EB',
  alto: '#E0E0E0',
  carnation: '#F25757',
  treePoppy: '#FA9917',
  conifer: '#B0D344',
  deYork: '#72c07a',
  saffron: '#F3CA3E',
  cornflowerBlue: '#6195ED',
  mosque: '#016678',
  pickledBluewood: '#2F4858',
  offYellow: '#dec68c',
  charmPink: '#e3879e',
  frenchMauve: '#d183c9',
  brickRed: '#fb4824',
  white: '#fff',
  slate: '#4a3943',
  jaffa: '#F18E32',
  goldDrop: '#E08700',
  tulipTree: '#efb639',
  jungleGreen: '#21a464',
  pink: '#e59bb3',
  blush: '#e5a79b',
  fadedGreen: 'rgba(3, 128, 0, 0.6)',
  fadedBrown: 'rgba(189, 79, 0, 0.6)',
  fadedBlue: 'rgba(0,78,103, 0.6)',
  fadedPink: 'rgba(153,51,102, 0.6)',
  fadedPurple: 'rgba(107, 85, 145, 0.6)',
  fadedMaroon: 'rgba(81, 52, 77, 0.6)',
  fadedRed: 'rgba(188,0,0, 0.6)',
  fadedGrey: 'rgba(0, 0, 0, 0.03)',
  lightBeige: 'rgba(245, 240, 235, 0.4)',
  red: '#FF0000',
  brick: '#FB4824',
  periwinkleGray: '#B8C6E3',
  bone: '#f3f3f3',
  frostee: '#ebf8eb',
  fairPink: '#ffe8e8',
  hintOfRed: '#F5F2F2',
  pastelPink: '#ff99c8',
  pastelYellow: '#fcf6bd',
  pastelGreen: '#d0f4de',
  pastelBlue: '#a9def9',
  pastelPurple: '#e4c1f9',
};

export const AVATARCOLORS = [
  colors.pastelPink,
  colors.pastelYellow,
  colors.pastelGreen,
  colors.pastelBlue,
  colors.pastelPurple,
];
