import React from 'react';
import {isEqual, camelCase} from 'lodash';
import {useLocation} from 'react-router-dom';

export const useSearchParams = () => {
  const location = useLocation();

  const [state, setState] = React.useState<{
    [key: string]: string | undefined;
  }>({});

  React.useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const newState: {[key: string]: string} = {};
    searchParams.forEach(
      (value: string, key: string) => (newState[camelCase(key)] = value),
    );

    if (!isEqual(state, newState)) {
      setState(newState);
    }
  }, [location.search, state]);

  return state;
};
