import {Box, Typography} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {Theme} from '@mui/system';
import React from 'react';

export interface SidebarItemProps {
  isAccordion?: boolean;
  disabled?: boolean;
  icon: React.ReactNode;
  label: string;
  selected?: boolean;
  handleClick?: () => void;
  children?: React.ReactNode;
}

const useSideBarStyles = makeStyles<Theme, SidebarItemProps>(theme => ({
  item: {
    display: 'flex',
    padding: '6px',
    cursor: 'pointer',
    borderRadius: 4,
    justifyContent: 'space-between',
    transition: 'transform 0.3s ease-in-out',
    '&:hover': {
      transform: props => !props.isAccordion && 'translateX(6px)',
      transition: 'transform 0.1s ease-in-out',
    },
  },
  itemText: {
    display: 'inline',
    marginLeft: '8px',
    fontWeight: 'normal',
  },
  selected: {
    backgroundColor: theme.palette.action.hover,
    fontWeight: '600',
  },
  disabled: {
    color: theme.palette.grey[500],
  },
  title: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const SidebarItem = (props: SidebarItemProps) => {
  const styles = useSideBarStyles(props);

  const item = React.useMemo(() => {
    const onClick = () => {
      props.handleClick?.();
    };

    return (
      <Box
        className={`${styles.item} ${props.selected ? styles.selected : ''} ${
          props.disabled ? styles.disabled : ''
        }`}
        onClick={onClick}
        marginBottom="8px"
      >
        <Box className={styles.title}>
          {props.icon}
          <Typography
            variant="subtitle1"
            className={`${styles.itemText} ${
              props.selected ? styles.selected : ''
            }`}
          >
            {props.label}
          </Typography>
        </Box>
        {props.children}
      </Box>
    );
  }, [props, styles]);

  return item;
};

export default SidebarItem;
