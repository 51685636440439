import {Skeleton, SkeletonProps} from '@mui/material';

const ListItemSkeleton = (props: SkeletonProps) => {
  return (
    <Skeleton
      {...props}
      variant="rectangular"
      width="100%"
      height={props.height || 86}
      sx={{borderRadius: 1, mb: 2}}
    />
  );
};

export const ListSkeleton = (props: SkeletonProps) => {
  return Array.from({length: 10}).map((_, index) => (
    <ListItemSkeleton key={index} {...props} />
  ));
};
