import {makeStyles} from '@mui/styles';
import {ChangeEvent, useState, useCallback, useMemo} from 'react';
import {
  InputAdornment,
  TextField,
  TextFieldProps,
  debounce,
} from '@mui/material';
import {Search} from '@mui/icons-material';

type SearchBarProps = Omit<TextFieldProps, 'onChange'> & {
  initialValue?: string;
  onChange: (value: string) => void;
  label: string;
};

const useStyles = makeStyles(theme => ({
  root: {
    width: 300,
    marginBottom: 4,
    [theme.breakpoints.down('sm')]: {
      flexGrow: 1,
      width: '100%',
    },
  },
}));

export const SearchBar: React.FC<SearchBarProps> = props => {
  const [search, setSearch] = useState(props.initialValue || '');
  const styles = useStyles();

  const debouncedOnChange = useMemo(
    () =>
      debounce((value: string) => {
        props.onChange(value);
      }, 300),
    [props.onChange],
  );

  return (
    <TextField
      {...props}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search
              fontSize="small"
              sx={({palette}) => ({color: palette.grey[400]})}
            />
          </InputAdornment>
        ),
      }}
      value={search}
      variant="outlined"
      onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSearch(e.target.value);
        debouncedOnChange(e.target.value.toLowerCase());
      }}
      inputProps={{
        style: {
          height: 23,
        },
      }}
      className={styles.root}
    />
  );
};
