import {Backdrop, Box, IconButton} from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import {isImage} from '@/pages/ENT/Communities/utils';
import {useIsMobile} from '@/hooks/useIsMobile';

interface FileBackdropProps {
  file: File | string;
  open: boolean;
  onClose: () => void;
}

const FileBackdrop = ({file, open, onClose}: FileBackdropProps) => {
  const isMobile = useIsMobile('md');
  const extension =
    file instanceof File
      ? file.name.split('.').pop()?.toLowerCase()
      : file.split('?')[0]?.split('.').pop()?.toLowerCase();
  const fileUrl = file instanceof File ? URL.createObjectURL(file) : file;

  return (
    <Backdrop
      onClick={onClose}
      open={open}
      sx={{
        zIndex: theme => theme.zIndex.drawer + 1,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <IconButton
        aria-label="close"
        onClick={onClose}
        size="large"
        sx={{
          position: 'absolute',
          top: 16,
          right: 16,
          backgroundColor: 'white',
          padding: 0.5,
          borderRadius: 1,
        }}
      >
        <CloseRoundedIcon />
      </IconButton>
      {isImage(extension || '') && (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0,0,0,0.3)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            src={fileUrl}
            alt={file instanceof File ? file.name : ''}
            style={{
              maxWidth: '90%',
              maxHeight: '90%',
              objectFit: 'contain',
              borderRadius: 8,
            }}
          />
        </Box>
      )}
      {!isImage(extension || '') && (
        <iframe
          src={window.chrome ? `${fileUrl}#toolbar=0` : fileUrl}
          style={{
            width: isMobile ? '100%' : '60%',
            height: '100%',
            border: 'none',
            borderRadius: 4,
          }}
          title="file-preview"
        />
      )}
    </Backdrop>
  );
};

export default FileBackdrop;
