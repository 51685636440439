export interface FormValues {
  title: string;
  viewers: 'AL' | 'RE' | 'LE';
  file: File | null;
  link: string | undefined;
}

export const viewerOptions = [
  {value: 'AL', label: 'All'},
  {value: 'RE', label: 'Residents'},
  {value: 'LE', label: 'Leaseholders'},
];

export const isUrlPdf = (url: string | undefined) => {
  return url && url.split('.').pop()?.toLowerCase().split('?')[0] === 'pdf';
};

export const isImage = (extension: string) => {
  return ['png', 'jpg', 'jpeg', 'gif'].includes(extension?.toLowerCase());
};
